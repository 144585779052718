.live-chart {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  text-align: center; }
  .live-chart .start-button {
    margin: 0 auto; }
  .live-chart h1 {
    font-weight: 700;
    font-size: 60px;
    text-transform: uppercase;
    text-align: center;
    padding: 20px 10px;
    margin: 0; }
  .live-chart .chart {
    position: relative;
    margin: 20px auto; }
  .live-chart .baseline {
    position: absolute;
    top: 0;
    left: 50%;
    width: 2px;
    height: 100%;
    z-index: 2;
    background: #555; }
    .live-chart .baseline span {
      position: absolute;
      left: 0;
      top: -25px;
      transform: translateX(-50%);
      font-style: italic; }
  .live-chart .chart-bars {
    position: relative;
    width: 100%; }
    .live-chart .chart-bars.with-baseline .bar-wrapper {
      left: 0;
      padding-left: 50%; }
      .live-chart .chart-bars.with-baseline .bar-wrapper label {
        right: 50%;
        width: auto; }
      .live-chart .chart-bars.with-baseline .bar-wrapper.behind-baseline {
        left: auto;
        right: 0;
        padding-left: 0;
        padding-right: 50%;
        flex-direction: row-reverse; }
        .live-chart .chart-bars.with-baseline .bar-wrapper.behind-baseline label {
          right: auto;
          left: 50%;
          text-align: left;
          flex-direction: row-reverse; }
          .live-chart .chart-bars.with-baseline .bar-wrapper.behind-baseline label img {
            margin: 0 10px 0 0; }
        .live-chart .chart-bars.with-baseline .bar-wrapper.behind-baseline .value {
          right: 100%;
          left: auto;
          margin-left: 0;
          margin-right: 10px; }
  .live-chart .bar-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    transition: top 0.5s linear;
    padding-left: 200px;
    box-sizing: border-box;
    width: 100%;
    justify-content: flex-start; }
    .live-chart .bar-wrapper label {
      position: absolute;
      height: 100%;
      width: 200px;
      left: 0;
      padding: 0 10px;
      box-sizing: border-box;
      text-align: right;
      top: 50%;
      transform: translateY(-50%);
      font-size: 16px;
      font-weight: 700;
      display: flex;
      justify-content: flex-end;
      align-items: center; }
      .live-chart .bar-wrapper label img {
        max-height: 100%;
        max-width: 100%;
        margin-left: 10px; }
    .live-chart .bar-wrapper .value {
      font-size: 16px;
      font-weight: 700;
      margin-left: 10px; }
    .live-chart .bar-wrapper .bar {
      width: 0%;
      transition: width 0.5s linear; }
